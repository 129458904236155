import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Adventures from '@sections/Adventures';
import Team from '@sections/Team';
import Faq from '@sections/Faq';
import Contact from '@sections/Contact';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Adventures />
    {/* <Team /> */}
    {/* <Careers /> */}
    {/* <Faq /> */}
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
