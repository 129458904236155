import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

import ExternalLink from '@common/ExternalLink';

const Contact = () => (
  <Section id="contact">
    <StyledContainer>
      <p>
        We love to learn about new businesses and explore interesting opportunities. 
        <br/>
        If you think it's worth a chat, we’d love to hear 
        {` `}
        <ExternalLink href="mailto:partners@oyod.co">
          from you!
        </ExternalLink>
      </p>
    </StyledContainer>
  </Section>
);

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`;

export default Contact;
