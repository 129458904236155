import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import { ReactComponent as DigitalFirstRetailLogo } from '@images/logos/dfr.svg';
import { ReactComponent as BrandbaseLogo } from '@images/logos/brandbase.svg';
import { ReactComponent as Re4mLogo } from '@images/logos/re4m.svg';
import { ReactComponent as RotaReadyLogo } from '@images/logos/rotaready.svg';
import { ReactComponent as DynamifyLogo } from '@images/logos/dynamify.svg';
import { ReactComponent as FreetradeLogo } from '@images/logos/freetrade.svg';
import { ReactComponent as QuiverLogo } from '@images/logos/quiver.svg';

const Logos = [
  {
    logo: DigitalFirstRetailLogo,
    link: 'https://www.digitalfirstretail.com',
  },
  {
    logo: BrandbaseLogo,
    link: 'https://www.brandbase.io',
  },
  {
    logo: Re4mLogo,
    link: 'https://www.re4m.co',
  },
  // {
  //   logo: DirektekLogo,
  //   link: 'https://www.direktek.co.uk',
  // },
  
  {
    logo: RotaReadyLogo,
    link: 'https://www.rotaready.com',
  },
  {
    logo: DynamifyLogo,
    link: 'https://www.dynamify.com',
  },
  {
    logo: FreetradeLogo,
    link: 'https://www.freetrade.io',
  },
  {
    logo: QuiverLogo,
    link: 'https://www.getquiver.co.uk',
  }
];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="adventures" accent>
        <StyledContainer>
          <div>
            <h1>New venture, new adventure</h1>
            <LogoGrid>
              {Logos.map(({ logo, link }) => (
                <ExternalLink href={link}>{logo()}</ExternalLink>
              ))}
            </LogoGrid>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px 40px;
  justify-items: center;
  align-items: center;
  margin-top: 72px;
  
  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  min-height: 500px;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }

  h1 {
    text-align: right;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    h1 {
      text-align: left;
    }    
  }

  h3 {
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;
    text-decoration: underline;
  }
`;

const Art = styled.figure`
  width: 600px;
  position: absolute;
  top: -12%;
  right: 50%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 0;
    right: 65%;
    width: 500px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default UsedBy;
