import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>First an operator, second an investor</h2>
              <p>
                We build, operate and invest in consumer, retail &amp; technology businesses.
                We support our ventures with capital, infrastructure, shared services and 
                advisory.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>What we're interested in</h2>
              <p>
                We look for acquisitions of profitable businesses or partnerships. 
                <br/>
                Our sweet spot are businesses which fit the following criteria:
                <br/>
                <ul>
                  <li>Consumer or technology businesses</li>
                  <li>Underutilised IP</li>
                  <li>Turning our cost centres into profit centres</li>
                </ul>
                
                We make the occasional early stage venture and public investment.
                These are minority stakes, but in the case of our startup investments
                we are lucky enough to call these founders our friends, and try to help 
                where we can.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Focus on the long-term</h2>
              <p>
                As a family office, we invest our own capital allowing us the freedom to  
                make long-term decisions, to build sustainable businesses
                with an ideal holding period of <u>forever</u>.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 50px 0;
  
  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  p {
    max-width: 620px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
